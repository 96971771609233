

$('.header-mainnav > ul > li:has(ul)').addClass("has-subnav");


$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".hamburger").removeClass("is-active");
        $("#main-wrapper, .overlay").removeClass("off-canvas-active");
        $("#off-canvas").removeClass("active");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $(".popup").removeClass("active");
    }
});

$(".overlay").click(function () {
    $(".hamburger").removeClass("is-active");
    $("#main-wrapper, .overlay").removeClass("off-canvas-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
    $(".popup").removeClass("active");
});

$(function () {
    var header = $("#header-main, #off-canvas, .bookmarks-wrapper");
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 28) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});

$(".header-mainnav ul li.has-subnav").mouseover(function () {
    $(".overlay").addClass("menu-active");
});

$(".header-mainnav").mouseout(function () {
    $(".overlay").removeClass("menu-active");
});

$(".filter-select-wrapper ul").click(function () {
    $(this).toggleClass("open");
});

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");


    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});


$(".open-position-item, .popup-toggle").click(function () {
    currentposition = $(document).scrollTop();
    $('body,html').animate({scrollTop: 0}, 500);

    popupid = $(this).data("open");
    $(".popup").each(function(){
        if($(this).data("id") == popupid){
            $(this).addClass("active");
            $(this).find(".popup-close").attr("data-currentpos", currentposition);
        }
    });

    $(".overlay").addClass("open-position-active");
});

$(".popup-close").click(function () {
    $(".overlay").removeClass("open-position-active");
    $('body,html').animate({scrollTop: $(".popup.active").find(".popup-close").data("currentpos")}, 500);
    $(".popup").removeClass("active");
});

$(".popup-form-cta-wrapper > button").click(function () {
    $(".popup-form-wrapper").addClass("active");
});



// Slider Start

$('.slider').slick({
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: false,
    pauseOnHover: false,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
});
